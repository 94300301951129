import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import InvoicesPanel from "../components/Billing/InvoicesPanel";
import UsageCreditPanel from "../components/Billing/UsageCreditPanel";
import UsageDetailsPanel from "../components/Billing/UsageDetailsPanel";

import { apiBaseURL } from "../app/utils";

import axios from "axios";

function BillingPage() {
  const [billingState, setBillingState] = React.useState({
    invoices: [],
    totalCreditBalance: 0,
    usagePerModel: [],
  });
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${apiBaseURL}/billing`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .catch((error) => {})
      .then((response) => {
        setBillingState(response?.data);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return null;
  return (
    <React.Fragment>
      <Container sx={{ mb: 2, mt: 3 }}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 7 }}>
            <Typography
              sx={{ mt: 0.5, textAlign: { md: "left", xs: "center" } }}
              fontWeight={"bold"}
              variant="h3"
            >
              Billing Information
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 5 }}>
            <UsageCreditPanel
              totalCreditBalance={billingState?.totalCreditBalance}
            />
          </Grid>
          <Grid size={12}>
            {billingState?.usagePerModel && <UsageDetailsPanel usagePerModel={billingState?.usagePerModel} />}
          </Grid>
          {billingState?.invoices && billingState?.invoices.length > 0 && (
            <Grid size={12}>
              <InvoicesPanel invoices={billingState?.invoices} />
            </Grid>
          )}
        </Grid>
      </Container>
      <Divider />
      <Typography
        width={"100%"}
        display="block"
        variant="caption"
        sx={{ textAlign: "center", p: 1 }}
      >
        MyUserPrompt&copy; 2025
      </Typography>
    </React.Fragment>
  );
}

export default BillingPage;
