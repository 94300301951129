import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt } from "../../app/promptsSlice";

function VariableFormControl({ variableName }: { variableName: string }) {
  const dispatch = useAppDispatch();
  const variables = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.variables
  );
  const variable = variables[variableName as keyof typeof variables];

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVariable = event.target.value;
    const newVariables = { ...variables, [variableName]: newVariable };
    dispatch(updateSelectedPrompt({ variables: newVariables }));
  };

  const capitalize = (str: string) => {
    return String(str).charAt(0).toUpperCase() + String(str).slice(1);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          error={!variable || variable === ""}
          id={`${variableName}-textfield`}
          label={capitalize(variableName)}
          slotProps={{ inputLabel: { shrink: true } }}
          variant="outlined"
          value={variable}
          onChange={handleChange}
        />
      </FormControl>
    </Box>
  );
}

export default VariableFormControl;
