import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt } from "../../app/promptsSlice";

const getVariablesKeys = (basePrompt?: string) => {
  const regex = /\{\{(\w+?)\}\}/g;
  const matches = [];
  let match;

  while ((match = regex.exec(basePrompt || "")) !== null) {
    matches.push(match[1].trim());
  }

  return matches;
};

const mergeVariables = (variables: any, newVariablesKeys: any) => {
  const output: any = {};
  newVariablesKeys.forEach((variableKey: string) => {
    output[variableKey] = variables[variableKey] ? variables[variableKey] : "";
  });
  return output;
};

function BasePromptFormControl() {
  const dispatch = useAppDispatch();
  const basePrompt = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.basePrompt
  );
  const variables = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.variables
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newBasePrompt = event.target.value;
    const newVariablesKeys = getVariablesKeys(newBasePrompt);
    const newVariables = mergeVariables(variables, newVariablesKeys);
    dispatch(
      updateSelectedPrompt({
        basePrompt: newBasePrompt,
        variables: newVariables,
      })
    );
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          value={basePrompt}
          onChange={handleChange}
          multiline
          minRows={2}
          maxRows={32}
          slotProps={{
            inputLabel: { shrink: true },
          }}
          placeholder="e.g. Write an email about our upcoming conference"
          id="baseprompt-textfield"
          label="Base Prompt"
          variant="outlined"
        />
      </FormControl>
    </Box>
  );
}

export default BasePromptFormControl;
