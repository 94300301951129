import React from "react";
import { useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid2";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { useNavigate, useBlocker, useLocation } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { RootState } from "../app/store";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  savePrompt,
  duplicatePrompt,
  deletePrompt,
  Prompt,
} from "../app/promptsSlice";
import { getPromptMessages } from "../app/messagesSlice";
import SendIcon from "@mui/icons-material/Send";

import CircularProgress from "@mui/material/CircularProgress";
import ChatModal from "./ChatModal/ChatModal";

function EditPromptButtons() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const [openStopNavigationDialog, setOpenStopNavigationDialog] =
    React.useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openChatModal, setOpenChatModal] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState("");

  const selectedPrompt = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt
  );

  const isSaving = useAppSelector((state: RootState) => state.prompts.isSaving);
  const isDuplicating = useAppSelector(
    (state: RootState) => state.prompts.isDuplicating
  );
  const isDeleting = useAppSelector(
    (state: RootState) => state.prompts.isDeleting
  );

  const promptsMessages = useAppSelector(
    (state: RootState) => state.messages.promptsMessages
  );
  const isLoading = useAppSelector(
    (state: RootState) => state.messages.isLoading
  );

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (selectedPrompt.isEdited) {
        e.preventDefault();
        e.returnValue = "";
        return true;
      }
      return null;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [selectedPrompt.isEdited]);

  const blocker = useBlocker((tx) => {
    if (selectedPrompt.isEdited) {
      setOpenStopNavigationDialog(true);
      return true;
    }
    return false;
  });

  const handleOpenChatModal = () => {
    if (selectedPrompt.id) {
      const promptMessages = promptsMessages[selectedPrompt.id];
      if (!promptMessages) {
        dispatch(getPromptMessages(selectedPrompt))
          .unwrap()
          .then((promptMessages) => {
            setOpenChatModal(true);
          })
          .catch((error) => {
            setErrorMessage(error);
            setOpenSnackbar(true);
          });
      } else {
        setOpenChatModal(true);
      }
    }
  };

  const cachedOpenChatModal = useCallback(handleOpenChatModal, [
    dispatch,
    selectedPrompt,
    promptsMessages,
  ]);
  useEffect(() => {
    if (location.state && location.state.openChatModal) cachedOpenChatModal();
  }, [location, cachedOpenChatModal]);

  const handleCloseStopNavigationDialog = () => {
    setOpenStopNavigationDialog(false);
  };

  const handleCloseDeletePromptDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleSaveUnblock = () => {
    setErrorMessage("");
    dispatch(savePrompt(selectedPrompt))
      .unwrap()
      .then((newPrompt) => {
        if (blocker && blocker.proceed) {
          blocker.proceed();
        }

        setOpenStopNavigationDialog(false);
      })
      .catch((error) => {
        setErrorMessage(error);
        setOpenSnackbar(true);
      });
  };

  const handleUnblock = () => {
    if (blocker && blocker.proceed) {
      blocker.proceed();
    }

    setOpenStopNavigationDialog(false);
  };

  const handleSave = () => {
    const isNewPrompt = !selectedPrompt.id;
    setErrorMessage("");
    dispatch(savePrompt(selectedPrompt))
      .unwrap()
      .then((newPrompt) => {
        if (isNewPrompt) navigate(`/edit/${(newPrompt as Prompt).id}`);
      })
      .catch((error) => {
        setErrorMessage(error);
        setOpenSnackbar(true);
      });
  };

  const handleTest = () => {
    const isNewPrompt = !selectedPrompt.id;
    if (!isNewPrompt) handleOpenChatModal();
    else {
      setErrorMessage("");
      dispatch(savePrompt(selectedPrompt))
        .unwrap()
        .then((newPrompt) => {
          if (isNewPrompt)
            navigate(`/edit/${(newPrompt as Prompt).id}`, {
              state: { openChatModal: true },
            });
        })
        .catch((error) => {
          setErrorMessage(error);
          setOpenSnackbar(true);
        });
    }
  };

  const handleDeletePrompt = () => {
    setOpenDeleteDialog(true);
  };

  const handleDelete = () => {
    dispatch(deletePrompt(selectedPrompt))
      .unwrap()
      .then(() => {
        navigate("/new");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        setErrorMessage(error);
        setOpenSnackbar(true);
      });
  };

  const handleDuplicate = () => {
    setErrorMessage("");
    dispatch(duplicatePrompt(selectedPrompt))
      .unwrap()
      .then((newPrompt) => {
        navigate(`/edit/${(newPrompt as Prompt).id}`);
      })
      .catch((error) => {
        setErrorMessage(error);
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = () => {
    setErrorMessage("");
    setOpenSnackbar(false);
  };

  const handleCloseChatModal = () => {
    setOpenChatModal(false);
    if (location.state && location.state.openChatModal) {
      navigate(`/edit/${selectedPrompt.id}`, {
        replace: true,
      });
    }
  };

  return (
    <Grid container spacing={2}>
      {openChatModal && (
        <ChatModal open={openChatModal} onClose={handleCloseChatModal} />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar && errorMessage !== ""}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message=""
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid size={{ xs: 12, md: selectedPrompt.id ? 3 : 6 }}>
        <Button
          onClick={handleSave}
          disabled={
            selectedPrompt.basePrompt === "" ||
            selectedPrompt.isEdited === false ||
            isSaving ||
            isLoading ||
            isDeleting ||
            isDuplicating
          }
          variant="contained"
          color="success"
          startIcon={isSaving ? <CircularProgress size={20} /> : <SaveIcon />}
          fullWidth
        >
          Save
        </Button>
      </Grid>
      <Grid size={{ xs: 12, md: selectedPrompt.id ? 3 : 6 }}>
        <Button
          onClick={handleTest}
          disabled={
            selectedPrompt.basePrompt === "" ||
            isSaving ||
            isLoading ||
            isDeleting ||
            isDuplicating
          }
          variant="contained"
          color="warning"
          startIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
          fullWidth
        >
          Test
        </Button>
      </Grid>
      {selectedPrompt.id && (
        <Grid size={{ xs: 12, md: 3 }}>
          <Button
            disabled={
              selectedPrompt.basePrompt === "" ||
              isSaving ||
              isLoading ||
              isDeleting ||
              isDuplicating
            }
            onClick={handleDuplicate}
            variant="contained"
            color="secondary"
            startIcon={
              isDuplicating ? (
                <CircularProgress size={20} />
              ) : (
                <ContentCopyIcon />
              )
            }
            fullWidth
          >
            Duplicate
          </Button>
        </Grid>
      )}
      {selectedPrompt.id && (
        <Grid size={{ xs: 12, md: 3 }}>
          <Button
            disabled={isSaving || isLoading || isDeleting || isDuplicating}
            onClick={handleDeletePrompt}
            variant="contained"
            color="error"
            startIcon={
              isDeleting ? <CircularProgress size={20} /> : <DeleteIcon />
            }
            fullWidth
          >
            Delete
          </Button>
        </Grid>
      )}
      <Dialog
        open={openStopNavigationDialog}
        onClose={handleCloseStopNavigationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          All unsaved changes will be lost
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You've edited your prompt without saving it, are you sure you want
            to discard all unsaved changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {selectedPrompt.basePrompt &&
            selectedPrompt.basePrompt.trim() !== "" && (
              <Button disabled={isSaving} onClick={handleSaveUnblock}>
                Save Changes
              </Button>
            )}
          <Button disabled={isSaving} onClick={handleCloseStopNavigationDialog}>
            Cancel
          </Button>
          <Button
            disabled={isSaving}
            color="error"
            onClick={handleUnblock}
            autoFocus
          >
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeletePromptDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deleting This Prompt</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You're about to delete this prompt, this is irreversible, are you
            want to delete this prompt?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={isDeleting} onClick={handleCloseDeletePromptDialog}>
            Cancel
          </Button>
          <Button
            disabled={isDeleting}
            color="error"
            onClick={handleDelete}
            autoFocus
          >
            Delete Prompt
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default EditPromptButtons;
