import React, { useRef } from "react";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { RootState } from "../app/store";
import { useAppSelector } from "../app/hooks";
import { compilePrompt } from "../app/utils";
import { InputAdornment } from "@mui/material";

function MainPrompt() {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const textareaRef = useRef();

  const selectedPrompt = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt
  );
  const prompt = compilePrompt(selectedPrompt);

  const selectAll = (event: React.FocusEvent | React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target instanceof Element) {
      const eventElement = event.target as Element;
      if (eventElement.nodeName !== "TEXTAREA") return;
    } else return;
    const textarea = event.target as HTMLTextAreaElement;

    if (textarea.value !== "") {
      textarea.focus();
      textarea.select();
      navigator.clipboard.writeText(textarea.value);
      setOpenSnackbar(true);
    }
  };

  const copyPrompt = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (textareaRef && textareaRef.current) {
      const textarea = textareaRef.current as HTMLTextAreaElement;
      if (textarea.value !== "") {
        textarea.focus();
        textarea.select();
        navigator.clipboard.writeText(textarea.value);
        setOpenSnackbar(true);
      }
    }
  };

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Grid container spacing={0}>
      <Grid size={12}>
        <FormControl fullWidth>
          <TextField
            slotProps={{
              htmlInput: {
                readOnly: true,
              },
              inputLabel: { shrink: true },
              input: {
                endAdornment: (
                  <InputAdornment
                    sx={{ alignSelf: "baseline", mt: 1 }}
                    position="end"
                  >
                    <IconButton
                      sx={{ p: 1.5 }}
                      color="inherit"
                      aria-label="Copy Prompt"
                      onClick={copyPrompt}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            inputRef={textareaRef}
            onMouseDown={selectAll}
            multiline
            minRows={2}
            maxRows={32}
            value={prompt}
            id="outlined-basic"
            label="Result"
            variant="outlined"
          />
        </FormControl>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleClose}
          message=""
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Prompt Copied!
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default MainPrompt;
