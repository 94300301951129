import React from "react";
import Box from "@mui/material/Box";
import { apiBaseURL } from "../../app/utils";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import axios from "axios";

function UsageCreditPanel({
  totalCreditBalance,
}: {
  totalCreditBalance: number;
}) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAddCredits = async () => {
    try {
      setErrorMessage("");
      setOpenSnackbar(false);
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiBaseURL}/billing/checkout`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const { checkoutURL } = response.data;
      window.location.href = checkoutURL;
    } catch (error: any) {
      console.log(error);
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage(error.message);
        }
      } else {
        setErrorMessage(error);
      }
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setErrorMessage("");
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar && errorMessage !== ""}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message=""
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          borderRadius: 1,
          borderColor: "rgba(255, 255, 255, 0.23)",
          color: "rgba(255, 255, 255, 0.7)",
          margin: "-9px 0 0 0",
          padding: "5px 10px 8px 10px",
          display: "flex",
          borderWidth: 1,
        }}
        component="fieldset"
      >
        <legend
          style={{ fontSize: "0.75rem", paddingRight: 5, marginLeft: -2 }}
        >
          Usage Credit
        </legend>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          sx={{
            backgroundColor: "#424242",
            color: "#fff",
            borderRadius: 1,
            mr: 1,
            height: 50,
            p: 0.5,
            pr: 1,
            pl: 1,
          }}
          display="inline-block"
        >
          ${(totalCreditBalance / 100).toFixed(2)}
        </Typography>
        <Button
          onClick={handleAddCredits}
          disabled={isLoading}
          variant="contained"
          color="success"
          startIcon={isLoading ? <CircularProgress size={20} /> : <PaidIcon />}
          fullWidth
        >
          Add Credits
        </Button>
      </Box>
    </Box>
  );
}

export default UsageCreditPanel;
