import React, { SyntheticEvent, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt, audiences } from "../../app/promptsSlice";

function AudienceFormControl() {
  const dispatch = useAppDispatch();
  const audience = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.audience
  );
  const [possibleAudiences, setPossibleAudiences] = useState(audiences);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newAudience = event.target.value;
    const mappedAudiences = [...audiences];
    if (newAudience && newAudience !== "")
      mappedAudiences.unshift({ id: newAudience, name: newAudience });
    setPossibleAudiences(mappedAudiences);
    dispatch(updateSelectedPrompt({ audience: newAudience }));
  };

  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    newAudience: string | null
  ) => {
    dispatch(updateSelectedPrompt({ audience: newAudience }));
  };

  return (
    <Box>
      <FormControl fullWidth>
        <Autocomplete
          id="audience-textfield"
          freeSolo
          options={possibleAudiences.map((currentAudience) => currentAudience.name)}
          onChange={handleSelectChange}
          value={audience}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              onChange={handleChange}
              slotProps={{ inputLabel: { shrink: true } }}
              placeholder="e.g. Blog Readers, Domain Experts"
              label="Audience"
              helperText="Choose an audience or add your own"
            />
          )}
        />
      </FormControl>
    </Box>
  );
}

export default AudienceFormControl;
