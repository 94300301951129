import React from "react";
import Box from "@mui/material/Box";

import { apiBaseURL } from "../../app/utils";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import axios from "axios";

function AddCreditCTA({ totalCreditBalance }: { totalCreditBalance: number }) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAddCredits = async () => {
    try {
      setErrorMessage("");
      setOpenSnackbar(false);
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiBaseURL}/billing/checkout?redirectURL=${encodeURIComponent(
          window.location.href
        )}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const { checkoutURL } = response.data;
      window.location.href = checkoutURL;
    } catch (error: any) {
      console.log(error);
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage(error.message);
        }
      } else {
        setErrorMessage(error);
      }
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setErrorMessage("");
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        m: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 50,
        bottom: 70,
        right: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(4px)",
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar && errorMessage !== ""}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message=""
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          YOU HAVE USED ALL YOUR CREDITS!
        </Typography>
        <img src="/travolta.gif" alt="No more credits travolta confused" />
        <Button
          sx={{ m: 2, p: 1, pl: 3, pr: 3 }}
          onClick={handleAddCredits}
          disabled={isLoading}
          variant="contained"
          color="success"
          startIcon={isLoading ? <CircularProgress size={20} /> : <PaidIcon />}
          size="large"
        >
          Add More Credits
        </Button>
      </Box>
    </Box>
  );
}

export default AddCreditCTA;
