import React from "react";
import {
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Layout from "./components/Layout/Layout";
import HomePage from "./pages/HomePage";
import EditPage from "./pages/EditPage";
import { RootState } from "./app/store";
import { useAppSelector } from "./app/hooks";
import BillingPage from "./pages/BillingPage";

const ProtectedRoute = ({ children }: { children: any }) => {
  const user = useAppSelector((state: RootState) => state.authentication.user);
  if (!user) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout />}>
        <Route
          path="/new"
          element={
            <ProtectedRoute>
              <EditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit/:userPromptId"
          element={
            <ProtectedRoute>
              <EditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/billing"
          element={
            <ProtectedRoute>
              <BillingPage />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<HomePage />} />
      </Route>
    ),
    {
      future: {
        v7_relativeSplatPath: true, // Enables relative paths in nested routes
        v7_fetcherPersist: true, // Retains fetcher state during navigation
        v7_normalizeFormMethod: true, // Normalizes form methods (e.g., POST or GET)
        v7_partialHydration: true, // Supports partial hydration for server-side rendering
        v7_skipActionErrorRevalidation: true, // Prevents revalidation when action errors occur
      },
    }
  );

  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  );
}

export default App;
