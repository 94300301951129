import { useEffect, useRef } from "react";
import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Alert, Snackbar } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { RootState } from "../../app/store";
import { compilePrompt, getModelModelId } from "../../app/utils";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import AddCreditCTA from "../Billing/AddCreditCTA";

import { sendMessage } from "../../app/messagesSlice";
import MessageBox from "./Message";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function ChatModal({ open, onClose }: { open: boolean; onClose: any }) {
  const dispatch = useAppDispatch();
  const refMessagesContainer = useRef<null | HTMLDivElement>(null);
  const refMessagesInput = useRef<null | HTMLDivElement>(null);
  const user = useAppSelector((state: RootState) => state.authentication.user);
  const selectedPrompt = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt
  );
  const model: any | null = getModelModelId(selectedPrompt.model);
  const promptsMessages = useAppSelector(
    (state: RootState) => state.messages.promptsMessages
  );
  const isLoading = useAppSelector(
    (state: RootState) => state.messages.isLoading
  );

  const [messageText, setMessageText] = useState(compilePrompt(selectedPrompt));
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [requiresMoreCredits, setRequiresMoreCredits] = React.useState(false);

  useEffect(() => {
    if (open) {
      refMessagesContainer.current?.lastElementChild?.scrollIntoView(false);
      refMessagesInput.current?.focus();
      (refMessagesInput.current as HTMLInputElement).setSelectionRange(
        (refMessagesInput.current as HTMLInputElement).value.length,
        (refMessagesInput.current as HTMLInputElement).value.length
      );
    }
  }, [open]);

  useEffect(() => {
    refMessagesContainer.current?.lastElementChild?.scrollIntoView(false);
  }, [promptsMessages]);

  const cleanupFields = () => {
    setMessageText("");
  };

  const handleCloseSnackbar = () => {
    setErrorMessage("");
    setOpenSnackbar(false);
  };

  const dispatchSendMessage = () => {
    if (
      !requiresMoreCredits &&
      !isLoading &&
      messageText &&
      messageText.trim().length > 0
    ) {
      const isInitialPrompt =
        messageText.trim() === compilePrompt(selectedPrompt);
      const newMessage = {
        promptId: selectedPrompt.id,
        text: messageText,
        sender: user,
        createdAt: new Date().getTime(),
        model: selectedPrompt.model,
        isInitialPrompt,
      };

      setErrorMessage("");
      dispatch(sendMessage({ prompt: selectedPrompt, message: newMessage }))
        .unwrap()
        .then((response) => {
          refMessagesContainer.current?.lastElementChild?.scrollIntoView(false);
        })
        .catch((error) => {
          if (error.status === 409) {
            setRequiresMoreCredits(true);
          }
          setErrorMessage(error.message);
          setOpenSnackbar(true);
        });

      cleanupFields();

      refMessagesContainer.current?.lastElementChild?.scrollIntoView(false);
    }
  };

  const handleSendMessage = (
    event: FormEvent<HTMLFormElement> | React.MouseEvent
  ) => {
    event.preventDefault();
    dispatchSendMessage();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === "Enter" &&
      ((event.metaKey && navigator.platform.includes("Mac")) ||
        (event.ctrlKey && !navigator.platform.includes("Mac")))
    ) {
      dispatchSendMessage();
    }
  };

  const handleMessageTextChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newMessageText = event.target.value;
    setMessageText(newMessageText);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      disablePortal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar && errorMessage !== ""}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          message=""
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            maxWidth: `calc(100vw - 16px)`,
            height: `calc(var(--unit-100vh) - 16px)`,
            width: 1200,
            bgcolor: "background.paper",
            border: "2px solid #000",
            borderRadius: 2,
            boxShadow: 24,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              pl: 2,
              pt: 1,
              pb: 1,
              borderBottom: "#000 1px solid",
              display: "flex",
            }}
          >
            {model && [
              <Box key="model-avatar">
                <Avatar
                  sx={{ p: 0.5, mr: 1, backgroundColor: "white" }}
                  alt={model.name}
                  src={model.profileImageURL}
                />
              </Box>,
              <Box
                key="model-title"
                sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}
              >
                <Typography variant="button">
                  Testing with {model.name}
                </Typography>
              </Box>,
            ]}
            <Box sx={{ mr: 1 }}>
              <IconButton color="inherit" edge="start" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto",
              position: "relative",
              scrollbarColor: "gray transparent",
            }}
            ref={refMessagesContainer}
          >
            {selectedPrompt.id &&
              promptsMessages[selectedPrompt.id] &&
              promptsMessages[selectedPrompt.id].map(
                (message: any, idx: number) => (
                  <MessageBox
                    key={`message-${idx}`}
                    isFirstMessage={idx === 0}
                    currentUser={user}
                    message={message}
                  />
                )
              )}
            {isLoading && (
              <Box
                sx={{
                  m: 1,
                  clear: "both",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="typing">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Box>
            )}
            {requiresMoreCredits && <AddCreditCTA totalCreditBalance={0} />}
          </Box>
          <form onSubmit={handleSendMessage}>
            <Box sx={{ p: 1 }}>
              <TextField
                required
                inputRef={refMessagesInput}
                fullWidth
                id="message-text"
                variant="outlined"
                value={messageText}
                multiline
                maxRows={8}
                onChange={handleMessageTextChange}
                onKeyDown={handleKeyDown}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={
                            !(!!messageText && messageText.trim().length > 0) ||
                            isLoading ||
                            requiresMoreCredits
                          }
                          aria-label="Send Message"
                          onClick={handleSendMessage}
                          edge="end"
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}

export default ChatModal;
