import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseURL } from "./utils";
import { Prompt } from "./promptsSlice";

export function fetch() {
  return new Promise<{ data: string }>((resolve) =>
    setTimeout(() => resolve({ data: "success" }), 500)
  );
}

export interface User {
  displayName: string;
  email: string;
  profileImageURL: string;
  token?: string;
}
export interface Message {
  sender?: User;
  text: string;
  createdAt: number;
  model?: string;
  promptId?: string;
}
export interface MessagesState {
  promptsMessages: any;
  isLoading: boolean;
  error?: any;
}

const initialState: MessagesState = {
  promptsMessages: {},
  isLoading: false,
  error: undefined,
};

export const getPromptMessages = createAsyncThunk(
  "prompts/messages",
  async (prompt: Prompt, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiBaseURL}/prompts/${prompt.id}/messages`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      return { prompt, messages: response.data };
    } catch (error: any) {
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue(error.response.data.error);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendMessage = createAsyncThunk(
  "prompts/sendMessage",
  async (
    { prompt, message }: { prompt: Prompt; message: Message },
    thunkAPI
  ) => {
    try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${apiBaseURL}/prompts/${prompt.id}/messages`,
          message,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        const { userMessage, modelMessage } = response.data;
        return { prompt, userMessage, modelMessage };
    } catch (error: any) {
        console.log(error);
      if (error.message) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return thunkAPI.rejectWithValue({status: error.response.status, message: error.response.data.error});
        }
        return thunkAPI.rejectWithValue({status: 500, message: error.message});
      }
      return thunkAPI.rejectWithValue({status: 500, message: error});
    }
  }
);

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPromptMessages.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getPromptMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.prompt) {
          const promptId = action.payload.prompt.id;
          if (promptId)
            state.promptsMessages[promptId] = action.payload.messages;
        }
      })
      .addCase(getPromptMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(sendMessage.pending, (state, action) => {
        const { message, prompt } = action.meta.arg;
        if (prompt.id) {
          const promptMessages = state.promptsMessages[prompt.id];
          if (promptMessages) {
            promptMessages.push(message);
            state.promptsMessages[prompt.id] = promptMessages;
          } else {
            state.promptsMessages[prompt.id] = [message];
          }
        }
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isLoading = false;

        const { modelMessage } = action.payload;
        const { promptId } = modelMessage;
        if (promptId) {
          const promptMessages = state.promptsMessages[promptId];

          if (promptMessages) {
            promptMessages.push(modelMessage);
            state.promptsMessages[promptId] = promptMessages;
          } else {
            state.promptsMessages[promptId] = [modelMessage];
          }
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default messagesSlice.reducer;
