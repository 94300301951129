import React, { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import { Checkbox, FormControlLabel } from "@mui/material";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt, tones } from "../../app/promptsSlice";

function ToneFormControl() {
  const dispatch = useAppDispatch();
  const tone = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.tone
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedTone = event.target.name;
    let newTone = tone ? tone.filter(currentTone => (currentTone !== selectedTone)) : [];
    if (newTone.length === tone?.length) {
      // const toneObj = tones.find(currentTone =>   (currentTone.id === selectedTone))
      // newTone = newTone.filter(currentTone => (!toneObj?.disables?.includes(currentTone)))
      newTone.push(selectedTone);
    }
    dispatch(updateSelectedPrompt({ tone: newTone }));
  };

  return (
    <Box>
      <Box
        sx={{
          borderRadius: 1,
          borderColor: "rgba(255, 255, 255, 0.23)",
          color: "rgba(255, 255, 255, 0.7)",
          margin: "-9px 0 0 0",
          padding: "5px 10px",
          borderWidth: 1,
        }}
        component="fieldset"
      >
        <legend
          style={{ fontSize: "0.75rem", paddingRight: 5, marginLeft: -2 }}
        >
          Tone
        </legend>
        {tones.map((possibleTone) => (
          <FormControlLabel
            key={possibleTone.id}
            name={possibleTone.id}
            id={`tone-${possibleTone.id}`}
            sx={{ mt: "-7px" }}
            control={
              <Checkbox
                onChange={handleChange}
                checked={tone?.includes(possibleTone.id)}
              />
            }
            label={possibleTone.name}
          />
        ))}
      </Box>
    </Box>
  );
}

export default ToneFormControl;
