import {
  Prompt,
  models,
  defaultTemperature,
  defaultLength,
} from "./promptsSlice";

export const getCurrentMonth = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${year}`;
};

export const getPastMonths = (numberOfMonths: number) => {
  const date = new Date();
  const output = [getCurrentMonth(date)];

  for (let i = 1; i < numberOfMonths; i++) {
    date.setMonth(date.getMonth() - 1);
    output.unshift(getCurrentMonth(date));
  }

  return output;
};

export const apiBaseURL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://api.myuserprompt.com";

export function getPromptTitle(prompt: Prompt) {
  return prompt.title;
  // if (!prompt.basePrompt || prompt.basePrompt === "") return "";
  // return prompt.basePrompt.substring(0, 26).trim() + "...";
}

export function getPromptDate(prompt: Prompt) {
  if (!prompt.createdAt) return "";
  return new Date(prompt.createdAt).toDateString();
}

export function getModelModelId(modelId: string | undefined) {
  if (!modelId) return null;
  return models.find((currentModel) => currentModel.id === modelId);
}

function compileBasePrompt(basePrompt: string, variables: any) {
  return basePrompt.replace(/{{(.*?)}}/g, (_: any, word: string) => {
    return variables && variables[word] ? variables[word] : `{{${word}}}`;
  });
}

function compilePromptForOpenAI(prompt: Prompt) {
  const {
    audience,
    style,
    basePrompt,
    tone,
    persona,
    toExclude,
    toInclude,
    temperature,
    length,
    variables
  } = prompt;

  let output =
    basePrompt && basePrompt !== ""
      ? `${compileBasePrompt(basePrompt, variables)}`
      : "";
  let addedPreviousInstructions = false;
  const guidelinesTitle = "\n\nGuidelines: \n";
  if (output.trim() !== "") {
    if (persona && persona?.trim() !== "") {
      addedPreviousInstructions = true;
      output += `${guidelinesTitle}• Pretend you are a ${persona?.toLowerCase()}`;
    }
    if (style && style?.trim() !== "") {
      output += addedPreviousInstructions ? "\n" : guidelinesTitle;
      addedPreviousInstructions = true;
      output += `• Write it in a ${style?.toLowerCase()} style`;
    }
    if (audience && audience?.trim() !== "") {
      output += addedPreviousInstructions ? "\n" : guidelinesTitle;
      addedPreviousInstructions = true;
      output += `• The audience is ${audience?.toLowerCase()}`;
    }
    if (tone?.length && tone?.length > 0) {
      output += addedPreviousInstructions ? "\n" : guidelinesTitle;
      addedPreviousInstructions = true;
      output += "• The tone should be ";
      tone.forEach((currentTone, idx) => {
        if (idx === 0) output += currentTone?.toLowerCase();
        else if (idx === tone.length - 1)
          output += ` and ${currentTone?.toLowerCase()}`;
        else output += `, ${currentTone?.toLowerCase()}`;
      });
    }
    if (toInclude?.trim() !== "") {
      output += addedPreviousInstructions ? "\n" : guidelinesTitle;
      addedPreviousInstructions = true;
      output += `• Make sure to include the following: ${toInclude}`;
    }
    if (toExclude?.trim() !== "") {
      output += addedPreviousInstructions ? "\n" : guidelinesTitle;
      addedPreviousInstructions = true;
      output += `• Make sure to exclude the following: ${toExclude}`;
    }
    if (length && Math.abs(defaultLength - length) > 10) {
      output += addedPreviousInstructions ? "\n" : guidelinesTitle;
      addedPreviousInstructions = true;
      output += `• The text should be about ${length} words long`;
    }
    if (temperature && defaultTemperature !== temperature) {
      output += addedPreviousInstructions ? "\n" : guidelinesTitle;
      addedPreviousInstructions = true;
      output += `• Set your temperature to ${temperature / 100}`;
    }
  }

  return output;
}

function compilePromptForAnthropic(prompt: Prompt) {
  const {
    audience,
    style,
    basePrompt,
    tone,
    persona,
    toExclude,
    toInclude,
    temperature,
    length,
  } = prompt;

  let output = `${basePrompt}\n`;
  if (basePrompt?.trim() !== "") {
    if (audience) output += `\n <audience>${audience.toLowerCase()}</audience>`;
    if (style) output += `\n <style>${style.toLowerCase()}</style>`;
    if (persona) output += `\n <persona>${persona.toLowerCase()}</persona>`;
    if (tone && tone.length > 0)
      output += `\n <tone>${tone.join(",").toLowerCase()}</tone>`;
    if (toExclude)
      output += `\n <excludeFromOutput>${toExclude.toLowerCase()}</excludeFromOutput>`;
    if (toInclude)
      output += `\n <includeInOutput>${toInclude.toLowerCase()}</includeInOutput>`;
    if (temperature && defaultTemperature !== temperature)
      output += `\n <temperature>${temperature / 100}</temperature>`;
    if (length && Math.abs(defaultLength - length) > 10)
      output += `\n <length>${length} words</length>`;
  }

  return output;
}

export function compilePrompt(prompt: Prompt) {
  const { model } = prompt;
  const selectedModel = models.find(
    (currentModel) => currentModel.id === model
  );

  if (selectedModel) {
    if (selectedModel.organization === "Anthropic")
      return compilePromptForAnthropic(prompt);

    return compilePromptForOpenAI(prompt);
  }

  return "";
}
