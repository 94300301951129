import React from "react";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt } from "../../app/promptsSlice";

function valuetext(value: number) {
  return `${value} Words Long`;
}

function LengthFormControl() {
  const dispatch = useAppDispatch();
  const length = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.length
  );

  const handleChange = (
    event: Event,
    length: number | number[],
    activeThumb: number
  ) => {
    dispatch(updateSelectedPrompt({ length }));
  };

  return (
    <Box>
      <Box
        sx={{
          borderRadius: 1,
          borderColor: "rgba(255, 255, 255, 0.23)",
          color: "rgba(255, 255, 255, 0.7)",
          margin: "-9px 0 0 0",
          padding: "5px 10px",
          borderWidth: 1,
        }}
        component="fieldset"
      >
        <legend
          style={{ fontSize: "0.75rem", paddingRight: 5, marginLeft: -2 }}
        >
          Length
        </legend>
        <Slider
          aria-label="Length"
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          valueLabelFormat={valuetext}
          onChange={handleChange}
          value={length}
          step={1}
          min={1}
          max={1000}
        />
      </Box>
    </Box>
  );
}

export default LengthFormControl;
