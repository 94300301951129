import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateSelectedPrompt, models } from "../../app/promptsSlice";

function ModelFormControl() {
  const dispatch = useAppDispatch();
  const model = useAppSelector(
    (state: RootState) => state.prompts.selectedPrompt.model
  );

  const handleChange = (event: SelectChangeEvent) => {
    const newModel = event.target.value as string;
    dispatch(updateSelectedPrompt({ model: newModel }));
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="model-select-label">Model</InputLabel>
        <Select
          labelId="model-select-label"
          id="model-select"
          value={model}
          label="Model"
          onChange={handleChange}
        >
          <ListSubheader>Cheap & Fast Models</ListSubheader>
          {models.filter(possibleModel => possibleModel.type === 'cheap').map((possibleModel) => (
            <MenuItem value={possibleModel.id} key={possibleModel.id}>
              {possibleModel.name}
            </MenuItem>
          ))}
          <ListSubheader>Balanced Models</ListSubheader>
          {models.filter(possibleModel => possibleModel.type === 'balanced').map((possibleModel) => (
            <MenuItem value={possibleModel.id} key={possibleModel.id}>
              {possibleModel.name}
            </MenuItem>
          ))}
          <ListSubheader>Expensive & Sophisticated Models</ListSubheader>
          {models.filter(possibleModel => possibleModel.type === 'expensive').map((possibleModel) => (
            <MenuItem value={possibleModel.id} key={possibleModel.id}>
              {possibleModel.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default ModelFormControl;
