import Box from "@mui/material/Box";
import React from "react";
import { Divider, Avatar, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
declare global {
  interface Window {
    // Below just informs IDE and/or TS-compiler (it's set in `.js` file).
    markdown: any;
  }
}

function MessageBox({
  message,
  isFirstMessage,
  currentUser,
}: {
  message: any;
  isFirstMessage: boolean;
  currentUser: any;
}) {
  const [isCopied, setIsCopied] = React.useState(false);
  const isSentByCurrentUser = currentUser.id === message.sender.id;

  const copyMessage = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    navigator.clipboard.writeText(message.text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <React.Fragment>
      {message.isInitialPrompt && !isFirstMessage && (
        <Box sx={{ clear: "both" }}>
          <Typography
            sx={{ ml: 2, backgroundColor: "#121212", p: 1 }}
            variant="caption"
          >
            New Context
          </Typography>
          <Divider sx={{ ml: 1, mt: -1.3 }} />
        </Box>
      )}

      <Box
        sx={{
          maxWidth: isSentByCurrentUser ? "70%" : "100%",
          backgroundColor: isSentByCurrentUser ? "#424242" : "#222222",
          display: "flex",
          float: isSentByCurrentUser ? "right" : "left",
          clear: "both",
          m: 1,
          p: 1,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            flexGrow: 0,
            mr: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              p: message.sender.isModel ? 0.5 : 0,
              backgroundColor: "white",
            }}
            alt={message.sender.displayName}
            src={message.sender.profileImageURL}
          />
          <IconButton
            sx={{
              mt: 1,
              p: 1.5,
              position: "sticky",
              top: 2,
              height: "30px",
              width: "30px",
              fontSize: "0.9rem",
            }}
            aria-label="Copy Message"
            size="small"
            onClick={copyMessage}
          >
            {!isCopied && <ContentCopyIcon fontSize="inherit" />}
            {isCopied && <CheckCircleIcon fontSize="inherit" />}
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="caption"
            fontWeight="bold"
            display={"block"}
            sx={{ mb: -1 }}
          >
            {message.sender.displayName}
          </Typography>
          <Typography fontStyle={"italic"} variant="caption">
            {new Date(message.createdAt).toDateString()}
          </Typography>
          {message.sender.isModel && (
            <Typography
              variant="body1"
              sx={{
                clear: "both",
              }}
              dangerouslySetInnerHTML={{
                __html: window.markdown.parse(message.text),
              }}
            ></Typography>
          )}
          {!message.sender.isModel && (
            <Typography
              variant="body1"
              sx={{
                clear: "both",
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
              }}
            >
              {message.text}
            </Typography>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default MessageBox;
