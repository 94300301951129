import Box from "@mui/material/Box";
import { Link, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";

const tableCellStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: 0,
  marginRight: 8,
};

const typographyStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginRight: 8,
};

function InvoicesPanel({ invoices }: { invoices: any }) {
  return (
    <Box>
      <Box
        sx={{
          borderRadius: 1,
          borderColor: "rgba(255, 255, 255, 0.23)",
          color: "rgba(255, 255, 255, 0.7)",
          margin: "-9px 0 0 0",
          padding: "5px 10px",
          borderWidth: 1,
        }}
        component="fieldset"
      >
        <legend
          style={{ fontSize: "0.75rem", paddingRight: 5, marginLeft: -2 }}
        >
          Invoices
        </legend>
        <table style={{ width: "100%", textAlign: "left" }}>
          <thead>
            <tr>
              <th>Invoice</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Created</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice: any) => (
              <tr key={invoice.id}>
                <td style={tableCellStyle}>
                  <Typography style={typographyStyle}>{invoice.id}</Typography>
                </td>
                <td style={tableCellStyle}>
                  <Chip
                    label={invoice.status.toUpperCase()}
                    size="small"
                    sx={{ fontWeight: "bold" }}
                    color="success"
                  />
                </td>
                <td style={tableCellStyle}>
                  <Typography style={typographyStyle}>
                    $
                    {(
                      (invoice.subtotalExcludingTax + invoice.tax) /
                      100
                    ).toFixed(2)}
                  </Typography>
                </td>
                <td style={tableCellStyle}>
                  <Typography style={typographyStyle}>
                    {new Date(
                      invoice.createdAtStripe * 1000
                    ).toLocaleDateString()}
                  </Typography>
                </td>
                <td style={tableCellStyle}>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={invoice.hostedInvoiceURL}
                    underline="always"
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default InvoicesPanel;
