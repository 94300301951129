import Box from "@mui/material/Box";
import UsageDetailsGraph from "./UsageDetailsGraph";

function UsageDetailsPanel({ usagePerModel }: { usagePerModel: any }) {
  return (
    <Box>
      <Box
        sx={{
          borderRadius: 1,
          borderColor: "rgba(255, 255, 255, 0.23)",
          color: "rgba(255, 255, 255, 0.7)",
          margin: "-9px 0 0 0",
          padding: "5px 10px",
          borderWidth: 1,
        }}
        component="fieldset"
      >
        <legend
          style={{ fontSize: "0.75rem", paddingRight: 5, marginLeft: -2 }}
        >
          Usage Details
        </legend>
        <UsageDetailsGraph usagePerModel={usagePerModel} />
      </Box>
    </Box>
  );
}

export default UsageDetailsPanel;
