import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getPastMonths } from "../../app/utils";
import { models } from "../../app/promptsSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Monthly Usage",
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 4,
            }).format(context.parsed.y / 100);
          }
          return label;
        },
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const getModelsFromUsage = (usagePerModel: any) => {
  const output: Array<any> = [];

  usagePerModel.forEach((modelUsage: any) => {
    if (!output.includes(modelUsage.modelId)) {
      const model = models.find((model) => model.id === modelUsage.modelId);
      if (model) {
        output.push({
          id: modelUsage.modelId,
          name: model.name,
        });
      }
    }
  });

  return output;
};

const colors = [
  "#879A39",
  "#3AA99F",
  "#4385BE",
  "#D14D41",
  "#DA702C",
  "#D0A215",
  "#8B7EC8",
  "#CE5D97",
];

const getDataSetsFromUsagePerModel = (usagePerModel: any) => {
  const modelsUsed = getModelsFromUsage(usagePerModel);
  const months = getPastMonths(6);

  const output = modelsUsed.map((model, idx) => ({
    label: model.name,
    data: months.map((month) => {
      const modelUsageForMonth = usagePerModel.find((modelUsage: any) => {
        return modelUsage.modelId === model.id && modelUsage.month === month;
      });
      if (modelUsageForMonth) return modelUsageForMonth.totalTokensCost;
      return 0;
    }),
    backgroundColor: colors[idx],
  }));

  return output;
};

const generateData = (usagePerModel: any) => {
  const labels = getPastMonths(6);
  const datasets = getDataSetsFromUsagePerModel(usagePerModel);

  return { labels, datasets };
};

export default function UsageDetailsGraph({
  usagePerModel,
}: {
  usagePerModel: any;
}) {
  const data = generateData(usagePerModel);
  return <Bar options={options} data={data} />;
}
